export default [
  {
    title: 'Principal',
    icon: 'HomeIcon',
    route: 'home',
    resource: 'home',
    action: 'read',
  },
  {
    title: 'Painéis',
    icon: 'TrelloIcon',
    route: 'panel',
    resource: 'panel',
    action: 'read',
  },
  /* {
    title: 'Comunicados',
    icon: 'Volume2Icon',
    children: [
      {
        title: 'Todos',
        route: 'adm-communicate-list',
        resource: 'communicate',
        action: 'read',
        icon: 'ChevronRightIcon',
      },
      {
        title: 'Novo',
        route: 'adm-communincate-new',
        resource: 'communicate',
        action: 'create',
        icon: 'ChevronRightIcon',
      },
    ],
  }, */
  {
    title: 'Protocolos',
    icon: 'FilePlusIcon',
    resource: '*',
  },
  {
    title: 'Interação',
    icon: 'MessageCircleIcon',
    resource: '*',
  },
  {
    title: 'Clientes',
    icon: 'UsersIcon',
    resource: '*',
  },
  {
    title: 'Cadastros Gerais',
    icon: 'DatabaseIcon',
    resource: '*',
  },
  {
    title: 'Relatórios',
    icon: 'FileTextIcon',
    resource: '*',
  },
  {
    title: 'Usuários',
    icon: 'UserPlusIcon',
    children: [
      {
        title: 'Todos',
        route: 'users-list',
        resource: 'users',
        action: 'read',
        icon: 'ChevronRightIcon',
      },
    ],
  },
  {
    title: 'Perfis e Permissões',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'Perfis',
        route: 'role-list',
        resource: 'role',
        action: 'read',
        icon: 'ChevronRightIcon',
      },
      {
        title: 'Permissões',
        route: 'permission-list',
        resource: 'permission',
        action: 'read',
        icon: 'ChevronRightIcon',
      },
    ],
  },
]

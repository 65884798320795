<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale ? currentLocale.img: ''"
        height="14px"
        width="22px"
        :alt="currentLocale ? currentLocale.locale: ''"
      />
      <!--<span class="ml-50 text-body">{{ currentLocale ? currentLocale.name : '' }}</span>-->
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setApiLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import axios from '@axios'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      localize(this.$i18n.locale)
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  created() {
    if (this.$user) { this.$i18n.locale = this.$user.lang }
  },
  methods: {
    setApiLocale(locale) {
      axios.post('auth/language', { language: locale })
        .then(() => {
          this.$user.lang = locale
          localStorage.setItem('userData', JSON.stringify(this.$user))
          localize(locale)
          this.$i18n.locale = locale
        })
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/br.png'),
        name: 'Portuguese',
      },
      /* {
        locale: 'zh',
        img: require('@/assets/images/flags/zh.jpg'),
        name: 'Chinese',
      }, */
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>

<style>

</style>
